import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Alert, Spinner } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import { useUserContext } from '~providers/user'
import { validateEmail } from '~components/Shared/Functions'

const FormField = styled(Field)`
  font-size: 14px !important;
  border: none !important;
  border-bottom: 1px solid #8a8a8a !important;
  box-shadow: none !important;
  padding: 0 !important;
`

const TextLink = styled(Link)`
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
`

const ForgotPasswordForm = () => {
  const { useRequestPasswordReset } = useUserContext()
  const [requestPasswordReset] = useRequestPasswordReset()

  const onSubmit = async ({ email }, actions) => {
    try {
      const { data: { requestPasswordReset: { success } } } = await requestPasswordReset(email)

      if (success) {
        actions.setSubmitting(false)
        actions.setStatus({ success: `Thanks! If you're there is an account registered with the email ${email}, you will receive an email in your inbox.` })
      } else {
        actions.setSubmitting(false)
        const err = new Error('Something went wrong. Please verify your email address and try again.')
        actions.setErrors(err)
      }
    } catch (err) {
      actions.setErrors(err)
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: '' }}
    >
      {({
        isSubmitting,
        status,
        setSubmitting,
        isValid,
        setStatus,
        setErrors,
        values
      }) => (
        <Form>
          <FormField
            className="form-control mb-3"
            placeholder="Email address"
            type="email"
            name="email"
            validate={validateEmail}
            required
          />
          <button className="btn btn-primary w-100" type="submit" disabled={isSubmitting}>
            {isSubmitting ? <Spinner color="light" /> : 'Submit'}
          </button>
          {status && <Alert color="success" className="mt-2">{status.success}</Alert>}

          <div className="my-3">
            <TextLink to="/log-in">Remember your password? Log in</TextLink>
          </div>

          {!isValid && (
            <>
              {['email'].map((name, i) => (
                <ErrorMessage name={name} key={i}>
                  {errMsg => <Alert color="danger">{errMsg}</Alert>}
                </ErrorMessage>
              ))}
            </>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default ForgotPasswordForm
