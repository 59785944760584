import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Alert, Spinner } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import { useUserContext } from '~providers/user'

// TODO: Get reset password token through props. Router should pull token out of URL.
// TODO: Show success state.
// TODO: Show back-end errors
// TODO: Show validation errors
const FormField = styled(Field)`
  font-size: 14px !important;
  border: none !important;
  border-bottom: 1px solid #8a8a8a !important;
  box-shadow: none !important;
  padding: 0 !important;
`

const TextLink = styled(Link)`
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
`

function ResetPasswordForm ({ token }) {
  const { useResetPassword } = useUserContext()
  const [resetPassword] = useResetPassword()

  const onSubmit = async ({ password, passwordConfirmation, resetPasswordToken }, actions) => {
    try {
      const { data: { resetPassword: { success, errors } } } = await resetPassword(password, passwordConfirmation, resetPasswordToken)

      if (success) {
        actions.setSubmitting(false)
        actions.setStatus({ success: 'Your password has been reset. Please try logging in now' })
      } else {
        actions.setSubmitting(false)
        actions.setStatus({ msg: errors[0] })
        const err = new Error('Something went wrong. Please try again.')
        actions.setErrors(err)
      }
    } catch (err) {
      actions.setErrors(err)
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ password: '', passwordConfirmation: '', resetPasswordToken: token }}
    >
      {({
        isSubmitting,
        status,
        isValid,
        setSubmitting,
        values,
        setStatus,
        setErrors
      }) => (
        <Form>
          <FormField className="form-control mb-3" placeholder="Password" type="password" name="password" required />
          <FormField className="form-control mb-3" placeholder="Confirm password" type="password" name="passwordConfirmation" required />
          <Field type="hidden" name="resetPasswordToken" value={token} required />
          <button className="btn btn-primary w-100" type="submit" disabled={isSubmitting}>
            {isSubmitting ? <Spinner color="light" /> : 'Submit'}
          </button>
          {status && status.success && (
            <>
              <Alert color="success" className="mt-2">{status.success}</Alert>
              <TextLink to="/log-in">Log in</TextLink>
            </>
          )}
          <ErrorMessage name="password" component="div" />
          <ErrorMessage name="passwordConfirmation" component="div" />
          {status && status.msg && (
            <Alert className="mt-2" color="danger">
              {status.msg}
            </Alert>
          )}
          <div className="my-3">
            <TextLink to="/log-in">Remember your password? Log in</TextLink>
          </div>
        </Form>
      )}
    </Formik>
  )
}

ResetPasswordForm.propTypes = {
  setValid: PropTypes.func,
  token: PropTypes.string.isRequired
}

export default ResetPasswordForm
