import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

import '~components/all.scss'
import scssVars from '~components/scss-variables'
import { GuestRoute } from '~components/Routes'
import Layout from '~components/Layout'
import ForgotPasswordForm from '~components/Authentication/ForgotPasswordForm'
import ResetPasswordForm from '~components/Authentication/ResetPasswordForm'

const SectionHeading = styled.h2`
  font-weight: 200;
  color: ${scssVars.darkBrown};
  text-align: center;
  display: block;
  flex-wrap: wrap;
  display: flex;
  font-size: 44px;
  margin-bottom: 20px;
  line-height: 1.25;
  flex-direction: column;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: flex;
    flex-direction: row;
    text-align: left;
  }
`

const SectionSpan = styled.span`
  border: 1px solid ${scssVars.brown};
  height: 1px;
  margin: 0 auto 40px;
  width: 25%;
  display: block;
  margin: 25px auto;
  height: 1px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-left: 30px;
    margin-bottom: 0;
    flex-grow: 1;
  }
`

const SubHeader = styled.h2`
  font-weight: 600;
  font-size: 24px;
`

const BodyCopy = styled.div`
  p, li {
    font-size: 16px;
  }
  p {
    margin-bottom: 5px;
  }
  ul {
    padding-left: 20px !important;
  }
  li {
    line-height: 1.675;
  }
  a {
    font-style: italic;
    font-weight: 500;
    text-decoration: underline;
  }
`

const BodyAddendum = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px !important;
`

// This component is necessary because Gatsby prefers speed over accuracy in DOM diffing.
const Form = () => {
  const [hasMounted, setHasMounted] = useState(false)
  const [resetPasswordToken, setResetPasswordToken] = useState('')
  const location = useLocation()
  const { token } = queryString.parse(location.search)

  useEffect(() => {
    setResetPasswordToken(token)
  }, [token])

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return token ? <ResetPasswordForm token={resetPasswordToken} /> : <ForgotPasswordForm />
}

export default function ForgotPasswordPage ({ data }) {
  return (
    <GuestRoute>
      <Layout>
        <main>
          <section className="text-clr-sambuca pt-5 pb-5">
            <Container>
              <SectionHeading>
                <span>Reset Password</span>
                <SectionSpan />
              </SectionHeading>
              <Row>
                <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }}>
                  <SubHeader>Log in for a Better Experience</SubHeader>
                  <BodyCopy>
                    <p>
                      When you log in, you can:
                    </p>
                    <ul>
                      <li>Use our repair wizard, get cost estimates and get started with your repairs online</li>
                      <li>Schedule deliveries and pick-ups (NYC Only)</li>
                      <li>Set-up mail-in repairs and create shipping labels (Worldwide)</li>
                      <li>Pay for services</li>
                      <li>Expedite the checkout process</li>
                      <li>Manage your repairs and product order history</li>
                      <li>Manage your personal information</li>
                      <li>Get access to Leather Spa Member News</li>
                      <li>Receive custom offers on repairs and products (opt-in <FontAwesomeIcon icon={faQuestionCircle} style={{ color: scssVars.orange }} size="sm" /> for Leather Spa mailing list required)</li>
                    </ul>
                    <BodyAddendum className="mb-4">
                      Please Note: The information you provide us is for our use only and will be used solely for the purpose of communicating with you regarding your estimates and repairs.
                    </BodyAddendum>
                  </BodyCopy>

                </Col>
                <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 2 }}>
                  <Form />
                </Col>
              </Row>
              <h2 className="text-center mt-4" style={{ fontFamily: 'inherit' }}>Repair More. Buy Less.</h2>
            </Container>
          </section>
        </main>
      </Layout>
    </GuestRoute>
  )
}

ForgotPasswordPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}
